import galary1 from '../images/babaAtEye.jpeg'
import galary2 from '../images/babaChating.jpeg'
import galary3 from '../images/babaAtEye.jpeg'

   export const Data =[
    {
        testimony:`The testimony of a woman that came to the mountain whose
    husband has been bufetted by the menance of HIV/AID, she ran down
    to the mountain to call upon the name of the God of the mountain
    of possiblities, she did a virgil and plea with the God of these
    mountain and took water from these mountain and returned back
    to Abuja, upon arrival home her husband drank the water, days
    later they went back to the hospital and these woman's husband
    was confirmed healed and the sickness was gone, praise the Lord.`,
    testifier:'Mrs. Arinde'
    },
    {
        testimony:`The testimony of a woman that came to the mountain to 
        pray for a man that is to be operated on, she stood in the gap
        for these man, she took water from the mountain home and gave
        to these man, subsequently the doctor ran checks on him before 
        performing the operation, the result of kidney failure came out 
        negative as the doctors stood in shock and speackless as to how
        the traces of kidney failure would just disappear, still ammazed 
        as to what was happening the doctor called all other doctors to 
        confirm this latest development, all new result confirm that traces 
        of the kidney disease has gone and this man is healed, the water 
        from the mountain is indeed a miracle water.`,
        testifier:'"Sis Kemi"'
    },{
        testimony:`The testimony of a man that ran mad and was brought from Lagos to the
        mountain, before he was brought to the mountain he gave those bringing 
        him very though time, but they were able to bring him to the mountain 
        top he began recovering at a very fast pace, by three day he could identify 
        himself and in a month time he had fully recovered, he later went on to
         ministry and is well established today, praise the Lord!.`,
         testifier:'"Bro. G"'
    },
    {
        testimony:`The testimonies of those who have been trying to travel
        outside the country to no avial but as soon as they climb the mountain 
        and the man of god prays for them, their way will open in mysterious 
        way, validating the hand of God upon the mountain of possibility, 
        praise the Lord!`,
        testifier:''
    }
]