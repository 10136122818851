import galary0 from '../images/babaAtEye.jpeg'
import galary1 from '../images/babaChating.jpeg'
import galary2 from '../images/babaLeadingPeolple.jpeg'
import galary3 from '../images/babaLiftinghands.jpeg'
import galary4 from '../images/WhatsApp Image 2023-10-19 at 10.18.41 AM.jpeg'
import galary5 from '../images/babaPicture.jpeg'
import galary6 from '../images/babaPicture1.jpeg'
import galary7 from '../images/babaPicture2.jpeg'
import galary8 from '../images/babaPicture3.jpeg'
import galary9 from '../images/babaPicture4.jpeg'
import galary10 from '../images/babaPicture5.jpeg'
import galary11 from '../images/babaPreacing.jpeg'
import galary12 from '../images/congregation.jpeg'
import galary13 from '../images/congregationListening.jpeg'
import galary14 from '../images/congregationSiting.jpeg'
import galary15 from '../images/congregationSiting1.jpeg'

import galary16 from '../images/offeringTime.jpeg'
import galary17 from '../images/peoplePraying.jpeg'
import galary18 from '../images/peoplePraying1.jpeg'

import galary19 from '../images/peopleWaiting.jpeg'
import galary20 from '../images/praiseSession.jpeg'

export const Data =[
//     {
//         img:`${galary0}`
//     },
//    {
//      img:`${galary1}`
//     },
//     {
//       img:`${galary2}`
//     },
//     {
//       img:`${galary3}`
//     },
//     {
//       img:`${galary4}`
//     },
//     {
//       img:`${galary5}`
//     },
//     {
//       img:`${galary6}`
//     },
//     {
      
//       img:`${galary7}`
//     },
//     {
      
//       img:`${galary8}`
//     },
//     {  
//       img:`${galary9}`
//     },
//     {  
//       img:`${galary10}`
//     },
    {
      img:`${galary11}`
    },
    {
      img:`${galary12}`
    },
    {
      img:`${galary13}`
    },
    {
      img:`${galary14}`
    },
    {
      img:`${galary15}`
    },  
    {
        img:`${galary16}`
    },
    {
    img:`${galary17}`
    },
    {
    img:`${galary18}`
    },
    {
    img:`${galary19}`
    },
    {
    img:`${galary20}`
    }
]