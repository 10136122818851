import galary0 from '../images/babaAtEye.jpeg'
import galary1 from '../images/babaChating.jpeg'
import galary2 from '../images/babaLeadingPeolple.jpeg'
import galary3 from '../images/babaLiftinghands.jpeg'
import galary4 from '../images/WhatsApp Image 2023-10-19 at 10.18.41 AM.jpeg'
import galary5 from '../images/babaPicture.jpeg'
import galary6 from '../images/babaPicture1.jpeg'
import galary7 from '../images/babaPicture2.jpeg'
import galary8 from '../images/babaPicture3.jpeg'
import galary9 from '../images/babaPicture4.jpeg'
import galary10 from '../images/babaPicture5.jpeg'

export const Data =[
    {
        img:`${galary0}`
    },
   {
     img:`${galary1}`
    },
    {
      img:`${galary2}`
    },
    {
      img:`${galary3}`
    },
    {
      img:`${galary4}`
    },
    {
      img:`${galary5}`
    },
    {
      img:`${galary6}`
    },
    {
      
      img:`${galary7}`
    },
    {
      
      img:`${galary8}`
    },
    {  
      img:`${galary9}`
    },
    {  
      img:`${galary10}`
    },
    
]